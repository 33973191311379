import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ProductsByCategory from '../components/productsByCategory'
import ProductsByAZ from '../components/productsByAZ'
import { MDBContainer, MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, } from 'mdbreact'
import get from 'lodash/get'

class TabsDefault extends React.Component {
  state = {
    activeItem: '1',
  }

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      })
    }
  }

  render() {
    const data = get(this.props, 'data')
    const az = get(this, 'props.data.az.edges')
    const categories = get(this, 'props.data.categories.edges')
    const heroImage = get(this, 'props.data.heroImage')
    return (
      <div id="sub-page">
      <Layout>
        <SEO
          title="Products by A-Z and Category"
          description="Explore SoftLanding's innovative IBM i software solutions for enterprise IT, listed both alphabetically and by category."
          url="https://www.softlanding.com/products/"
          image={"https://www.softlanding.com" + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt=""
          title="Products"
          subtitle="Innovative software solutions for Enterprise IT."
          ctatext="Let's talk"
          ctaslug="/contact/"            
          type="root"
        />
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <p className="font-w-400 text-xs-extra-large divlink text-opening-para">
                  Realize the full potential of your Power Systems and IBM i environments with our enterprise IT software solutions.
                </p>
                <p className="text-medium mb-5 mt-3">                  
                  We're recognized as a leading global provider of application lifecycle management, problem and incident management, enterprise 
                  content management, automated operations, performance management, and menu management solutions for the IBM i, System i, 
                  iSeries and AS/400 platform.
                </p>
                <p>
                  Our software solutions help customers reach their business goals by streamlining processes and improving 
                  service levels while mitigating compliance risks and reducing costs.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBNav className="nav-tabs mt-5 mb-0">
                  <MDBNavItem className="nav-item-border">
                    <a
                      className={ this.state.activeItem === '1' ? 'nav-link active text-xs-small text-medium-small' : 'nav-link text-xs-small text-medium-small' }
                      onClick={this.toggle('1')}
                      role="tab"
                    >
                      A-Z
                    </a>
                  </MDBNavItem>
                  <MDBNavItem className="nav-item-border">
                    <a
                      className={ this.state.activeItem === '3' ? 'nav-link active text-xs-small text-medium-small' : 'nav-link text-xs-small text-medium-small' }
                      onClick={this.toggle('3')}
                      role="tab"
                    >
                      Category
                    </a>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItem}>
                  <MDBTabPane tabId="1" role="tabpanel">
                    <div className="intro-content">
                      <ProductsByAZ data={az} />
                    </div>
                  </MDBTabPane>
                  <MDBTabPane tabId="3" role="tabpanel">
                    <div className="intro-content">
                      <ProductsByCategory data={categories} />
                    </div>
                  </MDBTabPane>
                </MDBTabContent>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
      </div>
    )
  }
}
export default TabsDefault

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    az: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___title }
      filter: { frontmatter: { template: { eq: "products" }, hide: {ne: true} } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            description
            formerly
          }
          fields {
            slug
          }
        }
      }
    }
    categories: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___categoryname }
      filter: { frontmatter: { categoryname: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            categoryname
            categorydescription
            related {
              link
              name
            }
          }
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
        }
      }
    }
`